.fushata-container1 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
 
.fushata-hero {
  width: 100%;
  height: 1155px;
  display: flex;
  align-items: center;
  border-radius: 24px;
  flex-direction: column;
  background-size: cover;
  background-image: url("/landing-page-banner-desktop-1200h.webp");
  border-top-left-radius: var(--dl-radius-radius-radius2);
  border-top-right-radius: var(--dl-radius-radius-radius2);
}
 
.fushata-header1 {
  width: 100%;
  display: flex;
  justify-content: center;
}
 
.fushata-navbar {
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: 1440px;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
}
 
.fushata-left1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.fushata-navlink1 {
  display: contents;
}
 
.fushata-logo {
  width: 231px;
  height: 111px;
  object-fit: cover;
  text-decoration: none;
}
 
.fushata-right1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
 
.fushata-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
 
.fushata-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
 
.fushata-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 32px;
  z-index: 1000;
  position: fixed;
  transform: translateY(-100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
 
.fushata-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.fushata-container2 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
 
.fushata-image {
  width: 78px;
  height: 67px;
  text-decoration: none;
}
 
.fushata-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.fushata-icon12 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
 
.fushata-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.fushata-text10 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
 
.fushata-link10 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
 
.fushata-link11 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
 
.fushata-link12 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
  text-decoration: none;
}
 
.fushata-navlink2 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
  text-decoration: none;
}
 
.fushata-links1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.fushata-link13 {
  color: var(--dl-color-scheme-white);
  font-size: 25px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
}
 
.fushata-link13:hover {
  opacity: 0.5;
}
 
.fushata-link14 {
  color: var(--dl-color-scheme-white);
  font-size: 25px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
}
 
.fushata-link14:hover {
  opacity: 0.5;
}
 
.fushata-link15 {
  color: var(--dl-color-scheme-white);
  font-size: 25px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
}
 
.fushata-link15:hover {
  opacity: 0.5;
}
 
.fushata-main {
  width: 100%;
  height: 976px;
  display: flex;
  position: relative;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
 
.fushata-content1 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.fushata-center {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: 220px;
  display: flex;
  z-index: 100;
  max-width: 900px;
  align-self: center;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.fushata-heading1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  position: relative;
  max-width: 900px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.fushata-header2 {
  color: rgb(255, 255, 255);
  font-size: 45px;
  font-style: normal;
  text-align: center;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 80px;
}
 
.fushata-how-it-works {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  background-color: #EFF0F2;
}
 
.fushata-centered-container1 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
}
 
.fushata-category1 {
  color: white;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  margin-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-fourunits);
  border-radius: 30px;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: row;
  background-color: #FFFFFF;
}
 
.fushata-headng {
  width: 1116px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
}
 
.fushata-text11 {
  fill: var(--dl-color-scheme-green80);
  color: var(--dl-color-scheme-green80);
  font-size: 40px;
  align-self: center;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 600;
  margin-bottom: var(--dl-space-space-twounits);
}
 
.fushata-text12 {
  color: var(--dl-color-gray-black);
  font-size: 20px;
  align-self: center;
  text-align: center;
  font-family: "Poppins";
  line-height: 35px;
}
 
.fushata-banner-advanced-analytics1 {
  width: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  background-color: #FEFEFE;
}
 
.fushata-centered-container2 {
  width: 1380px;
  display: flex;
  max-width: 1200px;
  align-items: center;
  justify-content: center;
}
 
.fushata-image-container1 {
  flex: 1;
  display: flex;
}
 
.fushata-cards-image1 {
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 0px 0px #d4d4d4;
  object-fit: contain;
}
 
.fushata-right2 {
  flex: 1;
  display: flex;
  margin-left: var(--dl-space-space-fourunits);
  flex-direction: column;
}
 
.fushata-text15 {
  color: var(--dl-color-scheme-green80);
  font-size: 40px;
  font-style: normal;
  text-align: right;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 45px;
}
 
.fushata-category2 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
}
 
.fushata-text16 {
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-style: normal;
  text-align: right;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 35px;
}
 
.fushata-banner1 {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  background-color: #EFF0F2;
}
 
.fushata-container3 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
 
.fushata-left2 {
  flex: 1;
  height: 427px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
 
.fushata-text20 {
  color: var(--dl-color-scheme-green80);
  font-size: 40px;
  font-style: normal;
  text-align: left;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 45px;
}
 
.fushata-text21 {
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-style: normal;
  text-align: left;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 35px;
}
 
.fushata-image-container2 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
 
.fushata-cards-image2 {
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 0px 0px #d4d4d4;
  object-fit: contain;
}
 
.fushata-banner-advanced-analytics2 {
  width: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  background-color: #FEFEFE;
}
 
.fushata-centered-container3 {
  width: 1380px;
  display: flex;
  max-width: 1200px;
  align-items: center;
  justify-content: center;
}
 
.fushata-image-container3 {
  flex: 1;
  display: flex;
}
 
.fushata-cards-image3 {
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 0px 0px #d4d4d4;
  object-fit: contain;
}
 
.fushata-right3 {
  flex: 1;
  display: flex;
  margin-left: var(--dl-space-space-fourunits);
  flex-direction: column;
}
 
.fushata-text22 {
  color: var(--dl-color-scheme-green80);
  font-size: 40px;
  font-style: normal;
  text-align: right;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 45px;
}
 
.fushata-category3 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
}
 
.fushata-text23 {
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-style: normal;
  text-align: right;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 35px;
}
 
.fushata-banner2 {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  background-color: #EFF0F2;
}
 
.fushata-container4 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
 
.fushata-left3 {
  flex: 1;
  height: 427px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
 
.fushata-text24 {
  color: var(--dl-color-scheme-green80);
  font-size: 40px;
  font-style: normal;
  text-align: left;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 45px;
}
 
.fushata-text25 {
  color: rgb(0, 0, 0);
  font-size: 20px;
  text-align: left;
  font-family: "Poppins";
  line-height: 35px;
}
 
.fushata-link16 {
  display: contents;
}
 
.fushata-book-btn {
  cursor: pointer;
  display: flex;
  align-self: flex-start;
  margin-top: var(--dl-space-space-fourunits);
  transition: 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 24px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  text-decoration: none;
  background-color: var(--dl-color-scheme-green100);
}
 
.fushata-book-btn:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
 
.fushata-text26 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
 
.fushata-image-container4 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
 
.fushata-cards-image4 {
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 0px 0px #d4d4d4;
  object-fit: contain;
}
 
.fushata-footer {
  gap: var(--dl-space-space-threeunits);
  color: white;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  background-color: #000000;
}
 
.fushata-content2 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
 
.fushata-information {
  gap: var(--dl-space-space-unit);
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.fushata-heading2 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 265px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
 
.fushata-link17 {
  display: contents;
}
 
.fushata-pasted-image {
  width: 102px;
  text-decoration: none;
}
 
.fushata-text27 {
  font-size: 20px;
  font-family: Poppins;
  line-height: 21px;
}
 
.fushata-socials {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 265px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
 
.fushata-link18 {
  display: contents;
}
 
.fushata-social1 {
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  transition: 0.3s;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: flex-start;
  text-decoration: none;
  background-color: rgba(236, 233, 234, 0.2);
}
 
.fushata-social1:hover {
  background-color: rgba(236, 233, 234, 0.6);
}
 
.fushata-icon14 {
  width: 18px;
  height: 18px;
}
 
.fushata-link19 {
  display: contents;
}
 
.fushata-social2 {
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  transition: 0.3s;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  padding-bottom: 16px;
  justify-content: flex-start;
  text-decoration: none;
  background-color: rgba(236, 233, 234, 0.2);
}
 
.fushata-social2:hover {
  background-color: rgba(236, 233, 234, 0.6);
}
 
.fushata-icon18 {
  width: 18px;
  height: 18px;
}
 
.fushata-link20 {
  display: contents;
}
 
.fushata-social3 {
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  transition: 0.3s;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: flex-start;
  text-decoration: none;
  background-color: rgba(236, 233, 234, 0.2);
}
 
.fushata-social3:hover {
  background-color: rgba(236, 233, 234, 0.6);
}
 
.fushata-icon20 {
  width: 18px;
  height: 18px;
}
 
.fushata-social4 {
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  transition: 0.3s;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: flex-start;
  background-color: rgba(236, 233, 234, 0.2);
}
 
.fushata-social4:hover {
  background-color: rgba(236, 233, 234, 0.6);
}
 
.fushata-icon24 {
  width: 18px;
  height: 18px;
}
 
.fushata-links2 {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  z-index: 15;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
 
.fushata-column1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
 
.fushata-header3 {
  font-size: 24px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 30px;
  padding-top: var(--dl-space-space-oneandhalfunits);
}
 
.fushata-link21 {
  color: rgb(196, 196, 196);
  font-size: 20px;
  font-family: "Poppins";
  line-height: 21px;
  text-decoration: none;
}
 
.fushata-link21:hover {
  color: rgba(196, 196, 196, 0.5);
}
 
.fushata-link22 {
  color: rgb(196, 196, 196);
  font-size: 20px;
  font-family: "Poppins";
  line-height: 21px;
  text-decoration: none;
}
 
.fushata-link22:hover {
  color: rgba(196, 196, 196, 0.5);
}
 
.fushata-link23 {
  color: rgb(196, 196, 196);
  font-size: 20px;
  font-family: "Poppins";
  line-height: 21px;
  text-decoration: none;
}
 
.fushata-link23:hover {
  color: rgba(196, 196, 196, 0.5);
}
 
.fushata-link24 {
  color: rgb(196, 196, 196);
  font-size: 20px;
  font-family: "Poppins";
  line-height: 21px;
}
 
.fushata-link24:hover {
  color: rgba(196, 196, 196, 0.5);
}
 
.fushata-link25 {
  color: rgb(196, 196, 196);
  font-size: 20px;
  font-family: "Poppins";
  line-height: 21px;
}
 
.fushata-link25:hover {
  color: rgba(196, 196, 196, 0.5);
}
 
.fushata-column2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
 
.fushata-header4 {
  font-size: 24px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 30px;
  padding-top: var(--dl-space-space-oneandhalfunits);
}
 
.fushata-link26 {
  color: rgb(196, 196, 196);
  font-size: 20px;
  font-family: "Poppins";
  line-height: 21px;
  text-decoration: none;
}
 
.fushata-link26:hover {
  color: rgba(196, 196, 196, 0.5);
}
 
.fushata-link27 {
  color: rgb(196, 196, 196);
  font-size: 20px;
  font-family: "Poppins";
  line-height: 21px;
  text-decoration: none;
}
 
.fushata-link27:hover {
  color: rgba(196, 196, 196, 0.5);
}
 
.fushata-text31 {
  color: #C4C4C4;
  width: 100%;
  font-size: 14px;
  max-width: 1200px;
  line-height: 21px;
}
 
@media(max-width: 1600px) {
  .fushata-hero {
    width: 100%;
    height: 916px;
  }
  .fushata-header2 {
    color: rgb(255, 255, 255);
    font-size: 45px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    line-height: 90px;
  }
  .fushata-text11 {
    text-align: center;
  }
  .fushata-right2 {
    height: 434px;
  }
  .fushata-text15 {
    line-height: 50px;
  }
  .fushata-text16 {
    color: rgb(0, 0, 0);
    font-size: 20px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 400;
    line-height: 30px;
  }
  .fushata-text17 {
    color: rgb(0, 0, 0);
    font-size: 20px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 400;
    line-height: 35px;
  }
  .fushata-text19 {
    color: rgb(0, 0, 0);
    font-size: 20px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 400;
    line-height: 35px;
  }
  .fushata-text20 {
    line-height: 50px;
  }
  .fushata-text21 {
    color: rgb(0, 0, 0);
    font-size: 20px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 400;
    line-height: 30px;
  }
  .fushata-right3 {
    height: 434px;
  }
  .fushata-text22 {
    line-height: 50px;
  }
  .fushata-text23 {
    color: rgb(0, 0, 0);
    font-size: 20px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 400;
    line-height: 30px;
  }
  .fushata-text24 {
    color: var(--dl-color-scheme-green80);
    font-size: 40px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    line-height: 50px;
  }
  .fushata-text25 {
    color: rgb(0, 0, 0);
    font-size: 20px;
    font-family: Poppins;
    line-height: 30px;
  }
}
 
@media(max-width: 1200px) {
  .fushata-hero {
    height: 730px;
    background-size: cover;
    background-image: url("/landing-page-banner-desktop-1200h.webp");
  }
  .fushata-how-it-works {
    padding: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-radius4);
  }
  .fushata-centered-container1 {
    border-radius: var(--dl-radius-radius-radius4);
  }
  .fushata-text11 {
    text-align: center;
  }
  .fushata-text12 {
    font-family: Poppins;
  }
  .fushata-text13 {
    font-family: Poppins;
  }
  .fushata-banner-advanced-analytics1 {
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .fushata-text15 {
    font-size: 38px;
    line-height: 45px;
  }
  .fushata-text16 {
    color: #000000;
    font-family: Poppins;
    line-height: 25px;
  }
  .fushata-text17 {
    color: #000000;
    font-size: 20px;
  }
  .fushata-text19 {
    color: #000000;
    font-size: 20px;
  }
  .fushata-banner1 {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .fushata-text20 {
    font-size: 38px;
    margin-top: var(--dl-space-space-oneandhalfunits);
    line-height: 45px;
  }
  .fushata-text21 {
    color: rgb(0, 0, 0);
    font-size: 20px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 400;
    line-height: 25px;
  }
  .fushata-banner-advanced-analytics2 {
    padding-top: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: 0px;
  }
  .fushata-text22 {
    color: var(--dl-color-scheme-green80);
    font-size: 38px;
    font-style: normal;
    margin-top: var(--dl-space-space-oneandhalfunits);
    font-family: Poppins;
    font-weight: 600;
    line-height: 45px;
  }
  .fushata-text23 {
    color: rgb(0, 0, 0);
    font-size: 20px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 400;
    line-height: 25px;
  }
  .fushata-banner2 {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .fushata-text24 {
    font-size: 38px;
    margin-top: var(--dl-space-space-oneandhalfunits);
    line-height: 45px;
  }
  .fushata-text25 {
    color: rgb(0, 0, 0);
    font-size: 20px;
    line-height: 25px;
  }
}
 
@media(max-width: 991px) {
  .fushata-navbar {
    z-index: 1000;
  }
  .fushata-right1 {
    display: none;
  }
  .fushata-burger-menu {
    display: flex;
    padding: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-round);
    background-color: #48787B;
  }
  .fushata-icon10 {
    fill: white;
  }
  .fushata-links1 {
    display: none;
  }
  .fushata-category1 {
    padding-left: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .fushata-headng {
    margin-left: var(--dl-space-space-fourunits);
  }
  .fushata-text11 {
    text-align: center;
  }
  .fushata-banner-advanced-analytics1 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .fushata-text15 {
    color: var(--dl-color-scheme-green80);
    font-size: 40px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
  }
  .fushata-text16 {
    font-size: 18px;
    line-height: 20px;
  }
  .fushata-text17 {
    font-size: 18px;
    line-height: 20px;
  }
  .fushata-text19 {
    font-size: 18px;
    line-height: 20px;
  }
  .fushata-banner1 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .fushata-text21 {
    color: rgb(0, 0, 0);
    font-size: 18px;
    align-self: center;
    font-family: Poppins;
    line-height: 20px;
  }
  .fushata-banner-advanced-analytics2 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .fushata-text22 {
    color: var(--dl-color-scheme-green80);
    font-size: 40px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
  }
  .fushata-text23 {
    font-size: 18px;
    line-height: 20px;
  }
  .fushata-banner2 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .fushata-text25 {
    color: rgb(0, 0, 0);
    font-size: 18px;
    align-self: center;
    font-family: Poppins;
    line-height: 20px;
  }
  .fushata-footer {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
}
 
@media(max-width: 767px) {
  .fushata-navbar {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .fushata-burger-menu {
    display: flex;
  }
  .fushata-image {
    width: 76px;
  }
  .fushata-main {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .fushata-center {
    padding-bottom: 100px;
  }
  .fushata-heading1 {
    gap: var(--dl-space-space-halfunit);
  }
  .fushata-header2 {
    font-size: 40px;
    line-height: 40px;
  }
  .fushata-how-it-works {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .fushata-headng {
    padding-bottom: 0px;
  }
  .fushata-banner-advanced-analytics1 {
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .fushata-centered-container2 {
    flex-direction: column-reverse;
  }
  .fushata-right2 {
    margin-left: 0px;
  }
  .fushata-text15 {
    color: var(--dl-color-scheme-green80);
    font-size: 40px;
    align-self: center;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .fushata-text16 {
    text-align: center;
    line-height: 24px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .fushata-text17 {
    text-align: center;
    line-height: 24px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .fushata-text19 {
    text-align: center;
    line-height: 24px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .fushata-banner1 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .fushata-container3 {
    flex-direction: column;
  }
  .fushata-left2 {
    margin-right: 0px;
  }
  .fushata-text20 {
    align-self: center;
    text-align: center;
  }
  .fushata-banner-advanced-analytics2 {
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .fushata-centered-container3 {
    flex-direction: column-reverse;
  }
  .fushata-right3 {
    margin-left: 0px;
  }
  .fushata-text22 {
    color: var(--dl-color-scheme-green80);
    font-size: 40px;
    align-self: center;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .fushata-text23 {
    text-align: center;
    line-height: 24px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .fushata-banner2 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .fushata-container4 {
    flex-direction: column;
  }
  .fushata-left3 {
    margin-right: 0px;
  }
  .fushata-text24 {
    align-self: center;
    text-align: center;
  }
  .fushata-footer {
    gap: var(--dl-space-space-twounits);
  }
  .fushata-content2 {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .fushata-information {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .fushata-heading2 {
    gap: var(--dl-space-space-halfunit);
  }
  .fushata-pasted-image {
    width: 100px;
  }
  .fushata-text27 {
    font-size: 12px;
    line-height: 18px;
  }
  .fushata-socials {
    gap: var(--dl-space-space-halfunit);
  }
  .fushata-social1 {
    padding: 12px;
  }
  .fushata-social2 {
    padding: 12px;
  }
  .fushata-social3 {
    padding: 12px;
  }
  .fushata-social4 {
    padding: 12px;
  }
  .fushata-links2 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: 100%;
    flex-direction: column;
  }
  .fushata-header3 {
    font-size: 16px;
    line-height: 24px;
  }
  .fushata-header4 {
    font-size: 16px;
    line-height: 24px;
  }
}
 
@media(max-width: 479px) {
  .fushata-hero {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-image: url("/landing-page-banner-mobile1-1500w.webp");
  }
  .fushata-navbar {
    padding: var(--dl-space-space-unit);
  }
  .fushata-logo {
    width: 179px;
    height: 104px;
  }
  .fushata-mobile-menu {
    padding: 16px;
  }
  .fushata-nav1 {
    height: 725px;
  }
  .fushata-container2 {
    height: 155px;
    align-self: center;
    margin-bottom: 0px;
    justify-content: center;
  }
  .fushata-image {
    width: 247px;
    height: 237px;
    align-self: center;
    margin-left: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
  }
  .fushata-icon12 {
    width: 48px;
    height: 48px;
  }
  .fushata-nav2 {
    align-self: center;
  }
  .fushata-text10 {
    font-size: 35px;
    align-self: center;
    font-style: normal;
    text-align: center;
    font-family: Poppins;
    font-weight: 500;
    margin-bottom: var(--dl-space-space-twounits);
    text-decoration: none;
  }
  .fushata-link10 {
    font-size: 35px;
    align-self: center;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    margin-bottom: var(--dl-space-space-twounits);
    text-decoration: none;
  }
  .fushata-link11 {
    font-size: 35px;
    align-self: center;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    margin-bottom: var(--dl-space-space-twounits);
    text-decoration: none;
  }
  .fushata-link12 {
    font-size: 35px;
    align-self: center;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    padding-bottom: var(--dl-space-space-twounits);
    text-decoration: none;
  }
  .fushata-navlink2 {
    font-size: 35px;
    align-self: center;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    text-decoration: none;
  }
  .fushata-content1 {
    width: auto;
  }
  .fushata-center {
    margin-top: var(--dl-space-space-twounits);
    padding-bottom: 0px;
  }
  .fushata-header2 {
    color: rgb(255, 255, 255);
    font-size: 40px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    line-height: 40px;
  }
  .fushata-how-it-works {
    padding: 0px;
    background-color: #EFF0F2;
  }
  .fushata-category1 {
    width: auto;
    height: 100%;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
  }
  .fushata-headng {
    width: auto;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .fushata-text11 {
    font-size: 36px;
  }
  .fushata-text12 {
    font-size: 18px;
    font-family: Poppins;
    line-height: 25px;
  }
  .fushata-text13 {
    font-family: Poppins;
  }
  .fushata-banner-advanced-analytics1 {
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .fushata-text15 {
    font-size: 38px;
    text-align: center;
    font-family: Outfit;
    line-height: 45px;
  }
  .fushata-text16 {
    text-align: center;
    font-family: Poppins;
    line-height: 25px;
  }
  .fushata-text17 {
    color: #000000;
    font-size: 20px;
    text-align: center;
  }
  .fushata-text19 {
    color: #000000;
    font-size: 20px;
    text-align: center;
  }
  .fushata-text20 {
    align-self: center;
    text-align: center;
  }
  .fushata-text21 {
    text-align: center;
    margin-bottom: var(--dl-space-space-unit);
  }
  .fushata-banner-advanced-analytics2 {
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .fushata-text22 {
    font-size: 40px;
    text-align: center;
    font-family: Outfit;
    line-height: 45px;
  }
  .fushata-text23 {
    text-align: center;
    line-height: 25px;
  }
  .fushata-banner2 {
    padding-bottom: 0px;
  }
  .fushata-text24 {
    align-self: center;
    text-align: center;
  }
  .fushata-text25 {
    text-align: center;
    line-height: 24px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .fushata-book-btn {
    align-self: center;
    margin-top: var(--dl-space-space-fourunits);
    padding-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .fushata-cards-image4 {
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .fushata-content2 {
    align-self: center;
  }
  .fushata-information {
    align-self: center;
  }
  .fushata-heading2 {
    max-width: 100%;
    align-items: center;
    justify-content: flex-start;
  }
  .fushata-pasted-image {
    align-self: center;
  }
  .fushata-text27 {
    color: #c4c4c4;
    font-size: 20px;
    align-self: center;
    text-align: center;
    line-height: 18px;
  }
  .fushata-socials {
    align-self: center;
    justify-content: center;
  }
  .fushata-links2 {
    width: 100%;
    align-self: center;
    flex-direction: column;
  }
  .fushata-column1 {
    align-self: center;
  }
  .fushata-header3 {
    font-size: 24px;
    align-self: center;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    line-height: 24px;
  }
  .fushata-link21 {
    color: rgb(196, 196, 196);
    font-size: 20px;
    align-self: center;
    line-height: 21px;
  }
  .fushata-link22 {
    color: rgb(196, 196, 196);
    font-size: 20px;
    align-self: center;
    line-height: 21px;
  }
  .fushata-link23 {
    color: rgb(196, 196, 196);
    font-size: 20px;
    align-self: center;
    line-height: 21px;
  }
  .fushata-link24 {
    color: rgb(196, 196, 196);
    font-size: 20px;
    line-height: 21px;
  }
  .fushata-link25 {
    color: rgb(196, 196, 196);
    font-size: 20px;
    line-height: 21px;
  }
  .fushata-column2 {
    align-self: center;
  }
  .fushata-header4 {
    font-size: 24px;
    align-self: center;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    line-height: 24px;
  }
  .fushata-link26 {
    color: rgb(196, 196, 196);
    font-size: 20px;
    align-self: center;
    font-family: Poppins;
    line-height: 21px;
  }
  .fushata-link27 {
    color: rgb(196, 196, 196);
    font-size: 20px;
    text-align: center;
    font-family: Poppins;
    line-height: 21px;
  }
  .fushata-text31 {
    color: rgb(196, 196, 196);
    font-size: 20px;
    text-align: center;
    font-family: Poppins;
    line-height: 21px;
  }
}
